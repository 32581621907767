import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';
import { AnimationOnScroll } from 'react-animation-on-scroll';



const CounterUpOne = () => {
    return (
        <section id="tarjeta">
            <div className="section section-padding bg-color-adverse2">
                <div className="container">
                <SectionTitle 
                        subtitle2="Representa tu marca"
                        title="Adverse Card"
                        title2="Tarjetas de Presentación Digitales"
                        description="Con nuestras tarjetas de presentación digitales podrás darle un toque especial a tus relaciones interpersonales y laborales."
                        textAlignment="heading-light"
                        textColor=""
                    />
                    <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={50}>
                    <div className="row">
                        <CounterUp colSize="" layoutStyle="" evenTopMargin="" />
                    </div>
                    </AnimationOnScroll>
                    <ul className="shape-group-20 list-unstyled">
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-33.png"} alt="Bubble" /></li>
                    </ul>
                    <ul className="shape-group-6 list-unstyled">
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
                    </ul>
                </div>
            </div>
        </section>
    )
}


export default CounterUpOne;