import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';


const BannerOne = () => {
    return (
        <section id="banner">
            <div className="banner banner-style-1">
                <div class="ripple-background">
                    <div class="circle xxlarge shade1"></div>
                    <div class="circle xlarge shade2"></div>
                    <div class="circle large shade3"></div>
                    <div class="circle medium shade4"></div>
                    <div class="circle small shade5"></div>
                </div>
                <div className="container">
                    <div className="row align-items-end align-items-xl-start">
                        <div className="col-lg-6">
                            <div className="banner-content">

                                <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                    <h1 className="title">Impulsamos tu marca en el mundo digital</h1>
                                    <span className="subtitle">Ayudamos a tu marca a despegar en el mundo digital mediante la creación de estrategias y contenido de calidad.</span>
                                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large">¡Contactar!</Link>
                                </AnimationOnScroll>
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <AnimationOnScroll  animateIn="slideInUp" duration={1} animateOnce={true} delay={200}>
                        <div className="banner-thumbnail">
                            <div className="large-thumb">
                                <Tilty perspective={3000}>
                                    <img src={process.env.PUBLIC_URL + "/images/banner/cohete.png"} alt="Shape" />
                                </Tilty>
                            </div>
                        </div>
                        </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-21">

                    <li className="shape shape-2">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-3">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-4">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-5">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-6">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-7">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                    </li>
                </ul>

            </div>
        </section>
    )
}

export default BannerOne;