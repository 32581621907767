import React from 'react';
import { Link } from "react-scroll";
import { FaInstagram} from "react-icons/fa";


const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a target="_blank" href="https://www.instagram.com/adversedigitalrd"><FaInstagram /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="col-xl-6 col-lg-7">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="footer-widget">
                                    <h6 className="widget-title">Adverse digital</h6>
                                    <div className="footer-menu-link">
                                        <ul className="list-unstyled">
                                            <li><Link to="services" activeClass="active" className="a" spy={true} smooth={true} duration={500} offset={40}>Servicios</Link></li>
                                            <li><Link to="sobre" activeClass="active" className="a" spy={true} smooth={true} duration={500}>Sobre Nosotros</Link></li>
                                            <li><Link to="proyectos" activeClass="active" className="a" spy={true} smooth={true} duration={500} offset={-50}>Portafolio</Link></li>
                                            <li><Link to="tarjeta" activeClass="active" className="a" spy={true} smooth={true} duration={500}>Tarjetas Digitales (Adverse Card)</Link></li>
                                            <li><a href={process.env.PUBLIC_URL + "/contact"}>Contacto</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. Derechos reservados por Adverse digital.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;