import React from 'react';
import SEO from '../common/SEO';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import FormOne from '../component/contact/FormOne';


const Contact = () => {
    return (
        <>
            <SEO title="Contacto" />
            <main className="main-wrapper">
                <HeaderTwo />


            <div className="section section-padding-3">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">¡Solicitar Servicios!</h3>
                                <FormOne />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">Telefono</h4>
                                <p>Siempre estamos disponibles para responder a todas sus inquietudes.</p>
                                <h4 className="phone-number"><a href="tel:8493798304">(849) 379 8304</a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>Nuestro equipo recibira su solicitud tan pronto como sea posible.</p>
                                <h4 className="phone-number"><a href="mailto:adversedigitalrd@gmail.com">adversedigitalrd@gmail.com</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="shape-group-11 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="line" /></li>
                </ul>
            </div>

            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;