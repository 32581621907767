import React from 'react';
import { Link } from 'react-router-dom';

const PropOne = ({projectStyle, portfolio}) => {


    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail">
					<Link to={process.env.PUBLIC_URL + `/proyectos`}>
						<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
					</Link>
				</div>
			</div>
		</>
    )
}

export default PropOne;
