import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';



const Data = [
    {  
        id: 1,
        light_icon: "/images/icon/icon-7.png",
        text: "Adverse card"
    },

   
];

const CounterUp = ({colSize, layoutStyle, evenTopMargin}) => {

    return (
        <>
            {Data.map((data) => (
                <div className={`${colSize} ${(data.id % 2  === 0) ? evenTopMargin : ""}`} key={data.id} >
                    <div className={`counterup-progress ${layoutStyle}`}>

                        { 

                            layoutStyle === "counterup-style-2" ? 
                            <div className="icon">

                            </div> : 
                            <div className="icon">
                                <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={80}>
                                    <img src={process.env.PUBLIC_URL + "/images/icon/icon-7.png"} class="imagen" alt="Icon1" />

                                    <img src={process.env.PUBLIC_URL + "/images/icon/icon-8.png"} class="imagen" alt="Icon2" />

                                    <img src={process.env.PUBLIC_URL + "/images/icon/icon-9.png"} class="imagen" alt="Icon3" />
                                </AnimationOnScroll>
                            </div> 
                        }

                        <div className="count-number h2">

                        </div>
                        <h6 className="title">{data.text}</h6>
                    </div>
                </div>
            ))}
        </>
    )
}



export default CounterUp;