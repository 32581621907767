import React from "react";
//import { Link } from "react-router-dom";
import { Link } from "react-scroll";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
        <Link to="services" activeClass="active" className="a" spy={true} smooth={true} duration={500} offset={40}>Servicios</Link>
        </li>
        <li>
          <Link to="sobre" activeClass="active" className="a" spy={true} smooth={true} duration={500}>Sobre Nosotros</Link>
        </li>
        <li>
          <Link to="proyectos" activeClass="active" className="a" spy={true} smooth={true} duration={500} offset={-50}>Portafolio</Link>
        </li>
        <li>
          <Link to="tarjeta" activeClass="active" className="a" spy={true} smooth={true} duration={500}>Tarjetas Digitales (Adverse Card)</Link>
        </li>
        <li>
          <a href={process.env.PUBLIC_URL + "/contact"}>Contacto</a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
