import React from 'react';

const PropTwo = ({projectStyle, portfolio}) => {
	
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail">
					<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
				</div>
			</div>
		</>
    )
}

export default PropTwo;