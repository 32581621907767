import React from 'react';
import SectionTitleProyecto from '../../elements/section-title/SectionTitleProyecto';
import ProjectPropOne from './itemProp/ProjectPropOne';
import ProjectData from "../../data/project/ProjectData.json";
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const portfolioData = ProjectData;


const ProjectThree = () => {
    return (
        <section id="proyectos">
            <div className="section section-padding pb--80 pb_lg--40 pb_md--20 pb_sm--0">
                <div className="container">
                    <SectionTitleProyecto 
                        title="Nuestros proyectos"
                        textAlignment="heading-left mb--40"
                        textColor=""
                    />
                    <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={50}>
                    <div className="row row-15">
                    
                    {portfolioData.slice(0, 8).map((data) => (
                        <div className="col-md-4" key={data.id}>
                            <ProjectPropOne projectStyle="project-style-2" portfolio={data}/>

                        </div>
                    ))}
                    <div class="container">
                        <div class="section-heading heading-light">
                            <Link to={process.env.PUBLIC_URL + "/proyectos"} className="axil-btn btn-fill-primary btn-large">¡Ver portafolio completo!</Link>
                        </div>
                    </div>
                    </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </section>
    )
}

export default ProjectThree;