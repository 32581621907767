import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import ProjectThree from '../component/project/ProjectThree';
import ServicePropOne from '../component/service/ServicePropOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitleServicio from '../elements/section-title/SectionTitleServicio';


const DigitalAgency = () => {

    return (
        <>
        <SEO title="Adverse digital"/>
        <main className="main-wrapper">
            <HeaderOne />
            <BannerOne />
            <section id="services">
                <div className="section section-padding">
                    <div className="container">
                        <SectionTitleServicio 

                            title="Servicios que <br> ofrecemos."
                            description=""
                            textAlignment="heading-left mb--20 mb_md--70"
                            textColor=""
                        />
                        <div className="row">
                            <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="6" marginTop="yes"/>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                    </ul>
                </div>
            </section>
            <AboutOne />
            <ProjectThree />
            <CounterUpOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

