import React from 'react';
import FormOne from '../contact/FormOne';
import { AnimationOnScroll } from 'react-animation-on-scroll';



const AboutOne = () => {
    return (
        <section id="sobre">
        

            <section className="section section-padding-equal bg-color-adverse">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                        <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={40}>
                            <div className="about-us">
                                <div className="section-heading heading-left mb-0">
                                    <h2 className="title mb--40">¡Manejamos, creamos y desarrollamos!</h2>
                                    <p>Manejamos tus redes sociales con la finalidad de ayudar a tu audiencia a conectar mejor con tu marca y de esta manera darle ese impulso que siempre soñaste. </p>
                                    <p>Creamos tu contenido como fotos personales o de productos, posts para subir a redes sociales y darle la mejor imagen a tu marca. </p>
                                    <p>Desarrollamos tus redes sociales que ayudaran a darle ese toque especial a tu marca y generaran una interacción ideal del cliente con tu producto. </p>
                                </div>
                            </div>
                        </AnimationOnScroll>
                        </div>
                    
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-form-box">
                                <h3 className="title">¿Qué esperas para ponerte en contacto con nosotros?</h3>
                            <FormOne />
                            
                            </div>
                        </div>
                    </div>
                </div>
            
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
        </section>
    )
}

export default AboutOne;