import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectTwo from '../component/project/ProjectTwo';


const Proyectos = () => {

    return (
        <>
        <SEO title="Nuestros proyectos" />
        <main className="main-wrapper">
            <HeaderTwo/>
            <ProjectTwo colSize="col-xl-4 col-md-6" columnGap="row-15"/>
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Proyectos;