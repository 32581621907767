import React from 'react';


const Logo = ({limage, dimage, simage}) => {
    return (
        <a href="/">
            <img className="light-version-logo" src={process.env.PUBLIC_URL + limage} alt="logo" />
            <img className="dark-version-logo" src={process.env.PUBLIC_URL + dimage} alt="logo" />
            <img className="sticky-logo" src={process.env.PUBLIC_URL + simage} alt="logo" />
        </a>
    )
}


export default Logo;